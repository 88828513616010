import {
  login,
  getCategoryList,
  getAdminCategoryType,
  getAdminCategory,
  drugService,
  getMolecularNamesList,
  getDiscountMasterList,
  getOverAllDiscountMasterList,
  getTaxMappingMasterList,
  getPurchaseOrderList,
  getIndentOrderList,
  getDrugCountList,
  getInventoryDrugList,
  pharmacyDetailService,
  warehouseDetailService,
  getpharmacyEmpDetailService,
  getPurchaseRequisationList,
  getPurchaseRequisationBalanceList,
  getWareHouseInventoryDataService,
  getpharmacyState,
  getcityData,
  getpincodeData,
  getpharmacylist,
  getPriceMappingListService,
  getOrderIdDataService,
  getPatientPharmacyNotPaidOrderList,
  getPatientPharmacyOrderList,
  getPatientDataListService,
  getPatientAddressDataListService,
  getWarehouseInventoryList,
  getPatientPharmacyPaidOrderList,
  vendorDetailService,
  pharmacyAllDetailService,
  getPatientPharmacyByStatus,
  getOrderHistoryService,
  getPatientdrug,
  changeStatusService,
  getOrderStatusListService,
  getOrderStatusChangeListService,
  getUploadTemplateStatusService,
  getTemplateStatusTypeService,
  getTaxMappingMasterPaginationList,
  getpharmacy,
  recordOfLoginandLogout,
  getCourierData,
  getPendingCourier,
  getCourier,
} from '../../services/services';
export const loadingAction = (loading) => async (dispatch, getState) => {
  dispatch({
    type: 'GLOBLE_OVERLOADING',
    payload: loading,
  });
};
export const submitButtonAction = (btnType) => async (dispatch, getState) => {
  dispatch({
    type: 'SUBMIT_BUTTON',
    payload: btnType,
  });
};
export const loginAction = (d, props) => async (dispatch, getState) => {
  dispatch(loadingAction(true));
  const res = await login(d);
  if (res) {
    await recordOfLoginandLogout({ empId: res.data.basicProfile.empId, entryType: 'login' });
    dispatch({
      type: 'LOGIN',
      payload: res.data,
    });
    if (res.status === 200 && res.data.firstLogin === false) {
      props.history.push('/dashboard');
    }
  }
  dispatch(loadingAction(false));
};

export const getCategory = (_c, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  let categoryList = [];
  if (_c) {
    categoryList = _c;
  } else {
    categoryList = [
      'Title',
      'BloodGroup',
      'EmployeeType',
      'JobType',
      'SalaryType',
      'EducationLevel',
      'Gender',
      'Designation',
      'Specialists',
      'CourseList',
      'Department',
      'Language',
    ];
  }

  categoryList.map(async (c) => {
    const data = await getCategoryList(c, props);
    if (data && data.items && data.items.length > 0) {
      dispatch({
        type: 'GET_CATEGORY',
        payload: data,
        name: c.toLowerCase(),
      });
    }
  });
  dispatch(loadingAction(false));
};
export const getDrugCategoryMasters = (_c, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  let categoryList = [];
  if (_c) {
    categoryList = _c;
  } else {
    categoryList = [
      'DRUG_CATEGORY',
      'DRUG_DISP_UNIT',
      'DRUG_DOSAGE_UNIT',
      'DRUG_INTAKE_MODE',
      'DRUG_ITEM_STATUS',
      'DRUG_PACK',
      'DRUG_SCHEDULE',
      'DRUG_TYPE',
      'EatTime',
      'DRUG_MANUFACTURE',
      'DRUG_HSN_CODE',
    ];
  }

  if (Array.isArray(categoryList) && categoryList.length > 0) {
    categoryList.map(async (c) => {
      const data = await getCategoryList(c, props);
      if (data && data.items && data.items.length > 0) {
        dispatch({
          type: 'GET_CATEGORY',
          payload: data,
          name: c.toLowerCase(),
        });
      }
    });
  } else {
    const data = await getCategoryList(categoryList);
    dispatch({
      type: 'GET_CATEGORY',
      payload: data,
      name: categoryList.toLowerCase(),
    });
  }
  dispatch(loadingAction(false));
};
export const getAdminCategoryTypeData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAdminCategoryType(props);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_CATEGORY_TYPE',
      payload: data.data,
    });
  } else {
    dispatch({
      type: 'GET_CATEGORY_TYPE',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getPatientDrugCount = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getPatientdrug(props);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_PATIENTDRUG_TYPE',
      payload: data?.data,
    });
  } else {
    dispatch({
      type: 'GET_PATIENTDRUG_TYPE',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getAdminCategoryData =
  (props, categoryType, status, pageNumber, itemPerPage, name) => async (dispatch) => {
    if (categoryType) {
      dispatch(loadingAction(true));
      const data = await getAdminCategory(categoryType, status, pageNumber, itemPerPage, name, props);
      if (data && data.data && data.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      if (data && data.data && data.data.items && data.data.items.length > 0) {
        dispatch({
          type: 'GET_CATEGORY',
          payload: data.data,
          name: categoryType,
        });
      } else {
        dispatch({
          type: 'GET_CATEGORY',
          payload: [],
          name: categoryType,
        });
      }
      dispatch(loadingAction(false));
    }
  };

export const getPharmacyEmpAction = (methord, data, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const resData = await dispatch(getpharmacyEmpDetailService(methord, data, props));
  dispatch({
    type: 'GET_PHARMACY_EMP_DATA',
    payload: resData?.items,
  });
  dispatch(loadingAction(false));
};
export const createPharmacyAction = (methord, data, id, props) => async (dispatch) => {
  if (id === 'get') {
    const resData = await dispatch(pharmacyDetailService(methord, data, '', props));
    dispatch({
      type: 'GET_PHARMACY_DATA',
      payload: resData,
    });
  } else if (id === 'post') {
    await dispatch(pharmacyDetailService(methord, data, '', props));
  } else {
    if (methord === 'GET') {
      const res = await dispatch(pharmacyDetailService(methord, data, id, props));
      if (res) {
        dispatch(loadingAction(false));
        return res;
      }
    } else {
      await dispatch(pharmacyDetailService(methord, data, id, props));
    }
  }
};
export const getAllPharmacyAction = (props) => async (dispatch) => {
  const resData = await dispatch(pharmacyAllDetailService(props));
  dispatch({
    type: 'GET_PHARMACY_DATA',
    payload: resData ? resData.items : [],
  });
};
export const getAllPharmacy = (props, day) => async (dispatch) => {
  const resData = await dispatch(getpharmacy(props, day));
  dispatch({
    type: 'GET_PHARMACYALL_DATA',
    payload: resData ? resData.items : [],
  });
};
export const createVendorAction = (methord, data, id, props) => async (dispatch) => {
  if (id === 'get') {
    const resData = await dispatch(vendorDetailService(methord, data, '', props));
    dispatch({
      type: 'GET_VENDOR_DATA',
      payload: resData,
    });
  } else if (id === 'post') {
    await dispatch(vendorDetailService(methord, data, '', props));
  } else {
    if (methord === 'GET') {
      const res = await dispatch(vendorDetailService(methord, data, id, props));
      if (res) {
        dispatch(loadingAction(false));
        return res;
      }
    } else {
      await dispatch(vendorDetailService(methord, data, id, props));
    }
  }
};
export const createWarehouseAction = (methord, data, id, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (id === 'get') {
    const resData = await dispatch(warehouseDetailService(methord, data, '', props));
    dispatch({
      type: 'GET_WAREHOUSE_DATA',
      payload: resData,
    });
  } else if (id === 'post') {
    await dispatch(warehouseDetailService(methord, data, '', props));
  } else {
    if (methord === 'GET') {
      const res = await dispatch(warehouseDetailService(methord, data, id, props));
      if (res) {
        dispatch(loadingAction(false));
        return res;
      }
    } else {
      await dispatch(warehouseDetailService(methord, data, id, props));
    }
  }
  dispatch(loadingAction(false));
};
export const createDrugAction = (methord, data, id, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (id === 'get') {
    const url = 'all/list/';
    const resData = await dispatch(drugService(methord, data, '', props, url));
    dispatch({
      type: 'GET_DRUG_DATA',
      payload: resData,
      name: 'drug',
    });
  } else if (id === 'post') {
    await dispatch(drugService(methord, data, '', props));
  } else {
    if (methord === 'GET') {
      const res = await dispatch(drugService(methord, data, id, props));
      if (res) {
        dispatch(loadingAction(false));
        return res;
      }
    } else {
      await dispatch(drugService(methord, data, id, props));
    }
  }
  dispatch(loadingAction(false));
};
export const getTemplateStatusTypeAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getTemplateStatusTypeService();
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data?.data?.length > 0) {
    dispatch({
      type: 'GET_TEMPLEATE_STATUS',
      payload: data?.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_TEMPLEATE_STATUS',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getCourierPendingData = (status, pageNumber, itemPerPage, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCourierData(status, pageNumber, itemPerPage, props);

  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data) {
    dispatch({
      type: 'GET_COURIER_DATA',
      payload: data?.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_COURIER_DATA',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getPendingCourierData = (status, pageNumber, itemPerPage, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getPendingCourier(status, pageNumber, itemPerPage, props);

  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data) {
    dispatch({
      type: 'GET_COURIERPENDING_DATA',
      payload: data?.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_COURIERPENDING_DATA',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getCourierList = (dat, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCourier(dat, props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_COURIER_LIST',
      payload: data,
    });
  } else {
    dispatch({
      type: 'GET_COURIER_LIST',
      payload: [],
    });
  }

  dispatch(loadingAction(false));
};
export const getUploadTemplateStatusAction = (searchCriteria, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getUploadTemplateStatusService(searchCriteria);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data?.data?.items?.length > 0) {
    dispatch({
      type: 'GET_UPLOADEDTEMPLEATE_STATUS',
      payload: data?.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_UPLOADEDTEMPLEATE_STATUS',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getDrugMolecularMasters = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getMolecularNamesList(props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_MOLECULAR_NAMES',
      payload: data,
    });
  }
  dispatch(loadingAction(false));
};
export const getpharmacyStatelist = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getpharmacyState(props);

  dispatch({
    type: 'GET_PHARMACY_STATE',
    payload: data.data,
  });

  dispatch(loadingAction(false));
};
export const getpharmacyCity = (dat, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getcityData(dat, props);

  dispatch({
    type: 'GET_PHARMACY_CITY',
    payload: data.data,
  });

  dispatch(loadingAction(false));
};
export const getpharmacyPin = (dat, dat1, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getpincodeData(dat, dat1, props);
  dispatch({
    type: 'GET_PHARMACY_PIN',
    payload: data.data,
  });

  dispatch(loadingAction(false));
};
export const getpharmacyname = (dat, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getpharmacylist(dat, props);

  dispatch({
    type: 'GET_PHARMACY_LIST',
    payload: data.items,
  });

  dispatch(loadingAction(false));
};
export const createDiscountAction = (methord, data, id, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (methord === 'GET') {
    const data = await getDiscountMasterList(methord, undefined, undefined, props);
    if (data && data.length > 0) {
      dispatch({
        type: 'GET_DISCOUNT_MASTER',
        payload: data,
      });
    }
  } else if (methord === 'POST') {
    await getDiscountMasterList(methord, data, undefined, props);
  } else if (methord === 'PUT') {
    await getDiscountMasterList(methord, data, id, props);
  } else if (methord === 'DELETE') {
    await getDiscountMasterList(methord, data, id, props);
  }
  dispatch(loadingAction(false));
};
export const createOverAllDiscountAction = (methord, data, id, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (methord === 'GET') {
    const data = await getOverAllDiscountMasterList(methord, undefined, undefined, props);
    if (data && data.length > 0) {
      dispatch({
        type: 'GET_OVERALL_DISCOUNT_MASTER',
        payload: data,
      });
    }
  } else if (methord === 'POST') {
    await getOverAllDiscountMasterList(methord, data, undefined, props);
  } else if (methord === 'PUT') {
    await getOverAllDiscountMasterList(methord, data, id, props);
  } else if (methord === 'DELETE') {
    await getOverAllDiscountMasterList(methord, data, id, props);
  }
  dispatch(loadingAction(false));
};
export const createTaxMappingAction = (methord, data, id, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (methord === 'GET') {
    if (id) {
      const data = await getTaxMappingMasterList(methord, {}, id, props);
      dispatch(loadingAction(false));
      return data;
    } else {
      const data = await getTaxMappingMasterList(methord, '', '', props);
      if (data && data.length > 0) {
        dispatch({
          type: 'GET_TAX_MAPPING_MASTER',
          payload: data,
        });
      }
    }
  } else if (methord === 'POST') {
    await getTaxMappingMasterList(methord, data, '', props);
  } else if (methord === 'PUT') {
    await getTaxMappingMasterList(methord, data, id);
  } else if (methord === 'DELETE') {
    await getTaxMappingMasterList(methord, data, id);
  }
  dispatch(loadingAction(false));
};
export const createTaxMappingPaginationAction = (props, searchCriteria) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getTaxMappingMasterPaginationList(searchCriteria, props);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data?.data?.items?.length > 0) {
    dispatch({
      type: 'GET_TAX_MAPPING_MASTER',
      payload: data?.data,
    });
  } else {
    dispatch({
      type: 'GET_TAX_MAPPING_MASTER',
      payload: data?.data?.items,
    });
  }
  dispatch(loadingAction(false));
};

export const createPurchaseOrderAction = (path, methord, datas, id) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (methord === 'POST' && id === 'get') {
    const data = await getPurchaseOrderList(path, methord, datas);
    if (data.items) {
      dispatch({
        type: 'GET_PURCHASE_ORDER_MASTER',
        payload: data.items,
      });
    } else
      dispatch({
        type: 'GET_PURCHASE_ORDER_MASTER',
        payload: [],
      });
  } else if (methord === 'POST' && id === 'post') {
    await getPurchaseOrderList(path, methord, datas);
  } else if (methord === 'GET') {
    const getData = await getPurchaseOrderList(path, methord);
    dispatch(loadingAction(false));
    return getData;
  } else if (methord === 'PUT') {
    const getData = await getPurchaseOrderList(path, methord, datas, id);
    dispatch(loadingAction(false));
    return getData;
  } else if (methord === 'DELETE') {
    const getData = await getPurchaseOrderList(path, methord, {}, id);
    dispatch(loadingAction(false));
    return getData;
  }
  dispatch(loadingAction(false));
};
export const createIndentOrderAction = (path, methord, datas, id) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (methord === 'POST' && id === 'get') {
    const data = await getIndentOrderList(path, methord, datas);
    if (data.items) {
      dispatch({
        type: 'GET_INDENT_ORDER_MASTER',
        payload: data.items,
      });
    }
  } else if (methord === 'POST' && id === 'post') {
    await getIndentOrderList(path, methord, datas);
  } else if (methord === 'GET') {
    const getData = await getIndentOrderList(path, methord);
    dispatch(loadingAction(false));
    return getData;
  } else if (methord === 'PUT') {
    const getData = await getIndentOrderList(path, methord, datas, id);
    dispatch(loadingAction(false));
    return getData;
  } else if (methord === 'DELETE') {
    const getData = await getIndentOrderList(path, methord, {}, id);
    dispatch(loadingAction(false));
    return getData;
  }
  dispatch(loadingAction(false));
};
export const createInventoryAction = (path, methord, datas, id) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (methord === 'POST' && id === 'get') {
    const data = await getInventoryDrugList(path, methord, datas);
    if (data.items) {
      dispatch({
        type: 'GET_INVENTORY_LIST',
        payload: data.items,
      });
    }
  } else if (methord === 'POST' && id === 'post') {
    await getInventoryDrugList(path, methord, datas);
  } else if (methord === 'GET') {
    const getData = await getInventoryDrugList(path, methord);
    dispatch(loadingAction(false));
    return getData;
  } else if (methord === 'PUT') {
    const getData = await getInventoryDrugList(path, methord, datas, id);
    dispatch(loadingAction(false));
    return getData;
  } else if (methord === 'DELETE') {
    const getData = await getInventoryDrugList(path, methord, {}, id);
    dispatch(loadingAction(false));
    return getData;
  }
  dispatch(loadingAction(false));
};

export const getDrugCount = (id) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getDrugCountList(id);
  dispatch(loadingAction(false));
  return getData;
};
export const getPurchaseRequisitionAction = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getPurchaseRequisationList();
  if (getData && getData.length > 0) {
    dispatch({
      type: 'GET_PURCHASEREQUISATION_LIST',
      payload: getData,
    });
  }
  dispatch(loadingAction(false));
};
export const getPurchaseRequisitionBalanceAction = (id) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getPurchaseRequisationBalanceList(id);
  dispatch(loadingAction(false));
  return getData;
};
export const getWareHouseInventoryDataAction = (id, drugName) => async (dispatch) => {
  const getData = await getWareHouseInventoryDataService(id, drugName);
  return getData;
};
export const getPatientPharmacyOrderAction =
  (status, pageNumber, itemPerPage, orderId, patientId, isActive, props) => async (dispatch) => {
    dispatch(loadingAction(true));
    const getData = await getPatientPharmacyOrderList(
      status,
      pageNumber,
      itemPerPage,
      orderId,
      patientId,
      isActive,
      props,
    );
    if (getData && getData.items && Array.isArray(getData.items) && getData.items.length > 0) {
      dispatch({
        type: 'GET_PATIENTPHARMACYORDER_LIST',
        payload: getData,
      });
    } else {
      dispatch({
        type: 'GET_PATIENTPHARMACYORDER_LIST',
        payload: [],
      });
    }
    dispatch(loadingAction(false));
  };
export const getPatientPharmacyNotPaidOrderAction = (pageNumber, itemPerPage, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getPatientPharmacyNotPaidOrderList(pageNumber, itemPerPage, props);
  if (getData && !Array.isArray(getData)) {
    dispatch({
      type: 'GET_PATIENTPHARMACYNOTPAIDORDER_LIST',
      payload: getData,
    });
  } else {
    dispatch({
      type: 'GET_PATIENTPHARMACYNOTPAIDORDER_LIST',
      payload: null,
    });
  }
  dispatch(loadingAction(false));
};
export const getPatientPharmacyPaidOrderAction = (status, pageNumber, itemPerPage, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getPatientPharmacyPaidOrderList(status, pageNumber, itemPerPage, props);
  if (getData && !Array.isArray(getData)) {
    dispatch({
      type: 'GET_PATIENTPHARMACYPAIDORDER_LIST',
      payload: getData,
    });
  } else {
    dispatch({
      type: 'GET_PATIENTPHARMACYPAIDORDER_LIST',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getPatientPharmacyOrderByStatusAction =
  (status, id, type, pageNumber, itemPerPage, props) => async (dispatch) => {
    dispatch(loadingAction(true));
    const getData = await getPatientPharmacyByStatus(status, id, type, pageNumber, itemPerPage, props);
    // if (getData) {
    if (getData && type === 'byId' && !Array.isArray(getData)) {
      dispatch({
        type: 'GET_PATIENTPHARMACYORDERSTATUS_LIST',
        payload: [getData] || [],
      });
    } else {
      dispatch({
        type: 'GET_PATIENTPHARMACYORDERSTATUS_LIST',
        payload: getData || [],
      });
    }
    // } else {
    //   dispatch({
    //     type: 'GET_PATIENTPHARMACYORDERSTATUS_LIST',
    //     payload: getData,
    //   });
    // }
    dispatch(loadingAction(false));
  };
export const getOrderHistoryAction = (orderId, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getOrderHistoryService(orderId, props);
  if (getData) {
    dispatch({
      type: 'GET_PATIENTPHARMACYORDERHISTORY_LIST',
      payload: getData.reverse(),
    });
  }
  dispatch(loadingAction(false));
};
export const getWarehouseInventoryAction = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getWarehouseInventoryList();
  if (getData.items) {
    dispatch({
      type: 'GET_WAREHOUSEINVENTORY_LIST',
      payload: getData.items,
    });
  }
  dispatch(loadingAction(false));
};
export const getAllPriceMappingAction = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getPriceMappingListService();
  if (getData.items && getData.items.length > 0) {
    dispatch({
      type: 'GET_PRICEMAPPING_LIST',
      payload: getData.items,
    });
  }
  dispatch(loadingAction(false));
};
export const getOrderIdDataAction = (id) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getOrderIdDataService(id);
  dispatch(loadingAction(false));
  return getData;
};
export const getPatientDataListAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getPatientDataListService(props);
  if (getData) {
    dispatch({
      type: 'GET_PATIENTDATA_LIST',
      payload: getData,
    });
  }
  dispatch(loadingAction(false));
};
export const getPatientAddressListAction = (patientId, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getPatientAddressDataListService(patientId, props);
  if (getData) {
    dispatch({
      type: 'GET_PATIENTADDRESSDATA_LIST',
      payload: getData,
    });
  }
  dispatch(loadingAction(false));
};
export const getPAOrderIdDataAction = (status, id) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await getPatientPharmacyOrderList(status, id);
  dispatch(loadingAction(false));
  return getData;
};
export const changeStatusAction = (data, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const getData = await changeStatusService(data, props);
  dispatch(loadingAction(false));
  return getData;
};
export const getOrderStatusListAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getOrderStatusListService(props);
  dispatch({
    type: 'GET_ORDERSTATUS_LIST',
    payload: data,
  });
  dispatch(loadingAction(false));
};
export const getOrderStatusChangeListAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getOrderStatusChangeListService(props);
  dispatch({
    type: 'GET_ORDERSTATUSCHANGE_LIST',
    payload: data?.reverse(),
  });
  dispatch(loadingAction(false));
};
