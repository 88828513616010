export const pharmaReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_DRUG_DATA':
      return {
        ...state,
        [action.name]: action.payload,
      };
    case 'GET_PHARMACY_EMP_DATA':
      return {
        ...state,
        pharmacyEmpData: action.payload,
      };
    case 'GET_PHARMACY_DATA':
      return {
        ...state,
        pharmacyData: action.payload,
      };
    case 'GET_COURIER_DATA':
      return {
        ...state,
        couirerPending: action.payload,
      };
    case 'GET_COURIERPENDING_DATA':
      return {
        ...state,
        PendingCourier: action.payload,
      };
    case 'GET_COURIER_LIST':
      return {
        ...state,
        courierlist: action.payload,
      };
    case 'GET_PHARMACYALL_DATA':
      return {
        ...state,
        pharmacyall: action.payload,
      };
    case 'GET_VENDOR_DATA':
      return {
        ...state,
        vendorData: action.payload,
      };
    case 'GET_PHARMACY_STATE':
      return {
        ...state,
        statedata: action.payload,
      };
    case 'GET_PHARMACY_CITY':
      return {
        ...state,
        citydata: action.payload,
      };
    case 'GET_PHARMACY_PIN':
      return {
        ...state,
        pindata: action.payload,
      };
    case 'GET_PHARMACY_LIST':
      return {
        ...state,
        pharmacylist: action.payload,
      };
    case 'GET_SHIFTTIMEMAP_DATA':
      return {
        ...state,
        shiftTimeMap: action.payload,
      };
    case 'GET_WAREHOUSE_DATA':
      return {
        ...state,
        warehouse: action.payload,
      };
    case 'GET_MOLECULAR_NAMES':
      return {
        ...state,
        molecularNames: action.payload,
      };
    case 'GET_DISCOUNT_MASTER':
      return {
        ...state,
        discountMaster: action.payload,
      };
    case 'GET_OVERALL_DISCOUNT_MASTER':
      return {
        ...state,
        overAllDiscountMaster: action.payload,
      };
    case 'GET_TAX_MAPPING_MASTER':
      return {
        ...state,
        taxMappingMaster: action.payload,
      };
    case 'GET_PURCHASE_ORDER_MASTER':
      return {
        ...state,
        purchaseOrders: action.payload,
      };
    case 'GET_INDENT_ORDER_MASTER':
      return {
        ...state,
        indentOrders: action.payload,
      };
    case 'GET_INVENTORY_LIST':
      return {
        ...state,
        inventoryList: action.payload,
      };
    case 'GET_PURCHASEREQUISATION_LIST':
      return {
        ...state,
        purchaseRequisationList: action.payload,
      };
    case 'GET_PATIENTPHARMACYORDER_LIST':
      return {
        ...state,
        patientPharmacyOrderList: action.payload,
      };
    case 'GET_PATIENTPHARMACYPAIDORDER_LIST':
      return {
        ...state,
        patientPharmacyPaidOrderList: action.payload,
      };
    case 'GET_PATIENTPHARMACYNOTPAIDORDER_LIST':
      return {
        ...state,
        patientPharmacyNotPaidOrderList: action.payload,
      };
    case 'GET_PATIENTPHARMACYORDERSTATUS_LIST':
      return {
        ...state,
        patientPharmacyOrderStatusList: action.payload,
      };
    case 'GET_PATIENTPHARMACYORDERHISTORY_LIST':
      return {
        ...state,
        patientPharmacyOrderHistoryList: action.payload,
      };
    case 'GET_PRICEMAPPING_LIST':
      return {
        ...state,
        priceMappingList: action.payload,
      };
    case 'GET_PATIENTDATA_LIST':
      return {
        ...state,
        patientDataList: action.payload,
      };
    case 'GET_PATIENTADDRESSDATA_LIST':
      return {
        ...state,
        patientAddressDataList: action.payload,
      };
    case 'GET_WAREHOUSEINVENTORY_LIST':
      return {
        ...state,
        warehouseInventoryDataList: action.payload,
      };
    case 'GET_ORDERSTATUS_LIST':
      return {
        ...state,
        statusLists: action.payload,
      };
    case 'GET_ORDERSTATUSCHANGE_LIST':
      return {
        ...state,
        statusChangeLists: action.payload,
      };
    case 'GET_TEMPLEATE_STATUS':
      return {
        ...state,
        getTemplateStatus: action.payload,
      };
    case 'GET_UPLOADEDTEMPLEATE_STATUS':
      return {
        ...state,
        getUploadedTemplateStatus: action.payload,
      };
    default:
      return state;
  }
};
export default pharmaReducer;
