import { toast } from 'react-toastify';
export const getToken = () => {
  return localStorage.getItem('accessToken');
};
export const removeToken = () => {
  localStorage.clear();
};
export const getloginDuration = () => {
  const time = localStorage.getItem('loginDuration');
  const sec = time * 60000;
  return sec;
};
export const getUsername = () => {
  return localStorage.getItem('username');
};
export const getfunctions = () => {
  return localStorage.getItem('functions');
};
export const faqPharmacy = [
  {
    id: 1,
    title: 'Where to order the medicine?',
    content:
      'In your Diahome app, you can click on the “Order Medicine” tab on the home screen to order the medicine immediately or later.',
  },
  {
    id: 2,
    title: 'How to order medicine?',
    content:
      'Go to the “Order Medicine” tab and click the “Place Order” button\n ◉ The list of medications will be available for you to view.  \n ◉ You can edit the number of units required for each medicine. \n ◉ You can edit the delivery address.  \n ◉ Upon confirmation, you’ll be directed to a third-party payment gateway. You can pay with a credit card, debit card, UPI, QR code, Wallet, Net banking and Pay Later. ',
  },
  {
    id: 3,
    title: 'Are you notified when the pharmacy order is placed?',
    content:
      'We will send you a confirmation email after we receive your order. Your pharmacist will also contact you to verify when the medicine has been shipped. ',
  },
];
export const getQtyFrmPack = (totalUnitsInPack, packCount) => {
  return totalUnitsInPack * packCount;
};

export const getIntegerFromString = (stringValue) => {
  const num = stringValue?.replace(/[^\d.]/g, '');
  return Number(num);
};

export const getDiscountAmount = (perc, total) => {
  const t = Number(perc) / 100;
  const amt = total * t;

  return amt;
};
export const calculateNetAmount = (data) => {
  // eslint-disable-next-line no-unused-vars
  const { sgst, cgst, igst, discount, totalAmount, fixedAmt, maxAmount, tax } = data;
  let discountAmt = 0;
  let taxAmount = 0;
  const perVal = tax / 100;
  taxAmount = Number(totalAmount) * perVal;
  if (Number(discount) > 0) {
    const disAmt = getDiscountAmount(Number(discount), Number(totalAmount));
    if (maxAmount > 0) {
      if (disAmt > maxAmount) discountAmt = maxAmount;
      else discountAmt = disAmt;
    } else discountAmt = disAmt;
  } else if (fixedAmt || fixedAmt === 0) discountAmt = fixedAmt;
  const netAmt = Number(totalAmount) + taxAmount - discountAmt;
  return { netAmount: netAmt, taxAmount: taxAmount, discountAmount: discountAmt };
};

export const getDiscount = (discount, total) => {
  let percentage;
  let fixedAmt;
  let maxAmt;
  if (discount.percentage) percentage = discount.percentage;
  else if (discount.fixedAmt) fixedAmt = discount.fixedAmt;
  else if (discount.maxAmt) maxAmt = discount.maxAmt;
  else percentage = 0;
  return { percentage: percentage, fixedAmt: fixedAmt, maxAmt: maxAmt };
};
export const handleWarnAlert = (reason) => {
  toast.warn(reason, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const patientDruginfo = [
  {
    patientInfo: {
      patientId: 'PT00000014',
      mobileNumber: '9790782719',
      firstName: 'Jagadesan',
      lastName: 'M',
      email: 'Jagadeshmariyappan@gmail.com',
      gender: {
        codeName: 'GEN1',
        name: 'Male',
        description: null,
        categoryType: 'Gender',
        isActive: true,
        priority: null,
        value: null,
        image: null,
      },
      dateOfBirth: '1989-04-07',
      age: 33,
      languages: [
        {
          codeName: 'Lan9',
          name: 'മലയാളം',
          description: null,
          categoryType: 'Language',
          isActive: true,
          priority: 3,
          value: null,
          image: null,
        },
      ],
      referralCode: 'RAJDIA472',
      familyMembers: [
        {
          patientId: 'PT00000018',
          mobileNumber: '8667244421',
          firstName: 'Kannan',
          lastName: 'K',
          email: 'Jagadeshmariyappgggan@gmail.com',
          gender: {
            codeName: 'GEN1',
            name: 'Male',
            description: null,
            categoryType: 'Gender',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          dateOfBirth: '1995-04-08',
          age: 27,
          languages: [
            {
              codeName: 'Lan17',
              name: 'தமிழ்',
              description: null,
              categoryType: 'Language',
              isActive: true,
              priority: 1,
              value: null,
              image: null,
            },
          ],
          relationType: null,
          documents: null,
          profilePicture: null,
          arNumber: null,
          alternateMobileNumber: null,
          landlineNumber: null,
        },
        {
          patientId: 'PT00000020',
          mobileNumber: '8667244422',
          firstName: 'Arvind',
          lastName: 'A',
          email: 'jagdesh195@gmail.com',
          gender: {
            codeName: 'GEN1',
            name: 'Male',
            description: null,
            categoryType: 'Gender',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          dateOfBirth: '1994-04-08',
          age: 28,
          languages: [
            {
              codeName: 'Lan3',
              name: 'English',
              description: null,
              categoryType: 'Language',
              isActive: true,
              priority: 2,
              value: null,
              image: null,
            },
          ],
          relationType: {
            codeName: 'REL14',
            name: 'Others',
            description: null,
            categoryType: 'RelationType',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          documents: null,
          profilePicture: null,
          arNumber: null,
          alternateMobileNumber: '',
          landlineNumber: null,
        },
        {
          patientId: 'PT00000026',
          mobileNumber: '6381256781',
          firstName: 'Arunkumar',
          lastName: 'S',
          email: 'arunkumar.2705@outlook.com',
          gender: {
            codeName: 'GEN1',
            name: 'Male',
            description: null,
            categoryType: 'Gender',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          dateOfBirth: '1987-04-09',
          age: 35,
          languages: [
            {
              codeName: 'Lan17',
              name: 'தமிழ்',
              description: null,
              categoryType: 'Language',
              isActive: true,
              priority: 1,
              value: null,
              image: null,
            },
            {
              codeName: 'Lan3',
              name: 'English',
              description: null,
              categoryType: 'Language',
              isActive: true,
              priority: 2,
              value: null,
              image: null,
            },
          ],
          relationType: null,
          documents: null,
          profilePicture: null,
          arNumber: null,
          alternateMobileNumber: null,
          landlineNumber: null,
        },
        {
          patientId: 'PT00000063',
          mobileNumber: '9333333333',
          firstName: 'Kenoz',
          lastName: 'Stanly',
          email: 'Vhvsh@gmail.com',
          gender: {
            codeName: 'GEN1',
            name: 'Male',
            description: null,
            categoryType: 'Gender',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          dateOfBirth: '1995-07-22',
          age: 27,
          languages: [
            {
              codeName: 'Lan17',
              name: 'தமிழ்',
              description: null,
              categoryType: 'Language',
              isActive: true,
              priority: 1,
              value: null,
              image: null,
            },
          ],
          relationType: {
            codeName: 'REL14',
            name: 'Others',
            description: null,
            categoryType: 'RelationType',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          documents: null,
          profilePicture: null,
          arNumber: 'AR09876',
          alternateMobileNumber: '',
          landlineNumber: null,
        },
        {
          patientId: 'PT00000065',
          mobileNumber: '1354207699',
          firstName: 'Test',
          lastName: 'Member',
          email: 'Ysvvsuvsj@hotmail.com',
          gender: {
            codeName: 'GEN1',
            name: 'Male',
            description: null,
            categoryType: 'Gender',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          dateOfBirth: '1999-07-27',
          age: 23,
          languages: [
            {
              codeName: 'Lan3',
              name: 'English',
              description: null,
              categoryType: 'Language',
              isActive: true,
              priority: 2,
              value: null,
              image: null,
            },
          ],
          relationType: {
            codeName: 'REL14',
            name: 'Others',
            description: null,
            categoryType: 'RelationType',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          documents: null,
          profilePicture: null,
          arNumber: null,
          alternateMobileNumber: '',
          landlineNumber: null,
        },
      ],
      relationType: null,
      documents: null,
      profilePicture:
        'https://storage.googleapis.com/diahome-dev-storage-public/patients/PT0000001420220707015303.jpeg',
      isActive: true,
      arNumber: 'AR000124',
      alternateMobileNumber: null,
      landlineNumber: null,
      referralOwner: {
        firstName: 'Raj',
        lastName: 'Kumar',
        empId: 'DHEMP00000005',
        empTitle: {
          codeName: 'TIT1',
          name: 'Mr.',
          description: null,
          categoryType: 'Title',
          isActive: true,
          priority: null,
          value: null,
          image: null,
        },
        photoLocation:
          'https://previews.123rf.com/images/kritchanut/kritchanut1406/kritchanut140600093/29213195-male-silhouette-avatar-profile-picture.jpg',
        roles: ['ROLE_ADMIN', 'ROLE_LAB_ADMIN', 'ROLE_DOCTOR'],
        mobileNumber: '7349519055',
        emailAddress: 'rajkumar.j@diahome.com',
        labCode: null,
        jobProfile: {
          departmentName: {
            codeName: 'DEP19',
            name: 'Doctor',
            description: null,
            categoryType: 'Department',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          designationName: {
            codeName: 'DES11',
            name: 'Collection - Team Leader',
            description: null,
            categoryType: 'Designation',
            isActive: true,
            priority: null,
            value: null,
            image: null,
          },
          specialists: [
            {
              codeName: 'SPE76',
              name: 'Diabetology',
              description: null,
              categoryType: 'Specialists',
              isActive: true,
              priority: 1,
              value: null,
              image: 'https://storage.googleapis.com/diahome-dev-storage-public/products/SPE76.png',
            },
          ],
        },
        grade: 'GD1',
      },
    },
    notifyDrugList: [
      {
        drugId: 'DHDRG0000000001',
        drugName: 'Dolo 650',
        hsnCode: 'HSN0001',
        manufacturer: 'MJEnterprises',
        batchNo: 'BA54456QWE',
        expiryDate: '2022-09-02',
        schedule: 'X',
        qty: 110,
        mrp: 27,
        price: 25,
        discount: 55,
        couponDiscount: 0,
        cgst: '5.0',
        sgst: '5.0',
        igst: '0.0',
        totalAmount: 2750,
        taxableAmount: 275,
        netAmount: 2970,
        prescriptionId: '',
        returnStatus: false,
        returnedDate: null,
        replaceRequested: false,
        replacementDate: null,
        remindPatientOn: 0,
      },
      {
        drugId: 'DHDRG0000000002',
        drugName: 'DOLO950',
        hsnCode: 'HSN0002',
        manufacturer: 'MJEnterprises',
        batchNo: 'BA54456PI',
        expiryDate: '2022-07-29',
        schedule: 'H1',
        qty: 31,
        mrp: 15,
        price: 41,
        discount: 12.71,
        couponDiscount: 0,
        cgst: '5.0',
        sgst: '5.0',
        igst: '0.0',
        totalAmount: 1271,
        taxableAmount: 127.10000000000001,
        netAmount: 1385.3899999999999,
        prescriptionId: '',
        returnStatus: false,
        returnedDate: null,
        replaceRequested: false,
        replacementDate: null,
        remindPatientOn: 0,
      },
    ],
  },
];
