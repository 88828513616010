export const globalReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TOKEN_VALID':
      return {
        tokenValid: action.payload,
      };
    case 'GET_STATE_DATA':
      return {
        ...state,
        states: action.payload,
      };
    case 'GET_CITY_DATA':
      return {
        ...state,
        cities: action.payload,
      };
    case 'GET_ZONE_DATA':
      return {
        ...state,
        zones: action.payload,
      };
    case 'GET_FUNCTIONS_DATA':
      return {
        ...state,
        functions: action.payload,
      };
    case 'GLOBLE_OVERLOADING':
      return {
        ...state,
        overlayLoading: action.payload,
      };
    case 'SUBMIT_BUTTON':
      return {
        ...state,
        submitButton: action.payload,
      };
    default:
      return state;
  }
};
export default globalReducer;
