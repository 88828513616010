import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getToken } from './utils/utils';

import Spinner from './components/Spinner';

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const PharmacyDetails = lazy(() => import('./pages/pharmacy/pharmacyDetails/PharmacyDetails'));
const PharmacyShopping = lazy(() => import('./pages/pharmacy/pharmacyshipping/Pharmacyshipping.js'));
const VenderDetails = lazy(() => import('./pages/pharmacy/VenderMaster/VenderMaster'));
const WarehouseProfile = lazy(() => import('./pages/pharmacy/warehouseProfile/WarehouseProfile'));
const PharmacyPreference = lazy(() => import('./pages/pharmacy/pharmacyPreference/PharmacyPreference'));

const Profile = lazy(() => import('./pages/profile/Profile'));
const Drugcount = lazy(() => import('./pages/pharmacy/patientdrugcount/PatientDrug'));

const Setting = lazy(() => import('./pages/pharmacy/setting-master/setting'));
// const PharmaDrugType = lazy(() => import('./pages/pharmacy/drug-type/DrugType'));
// const PharmaPack = lazy(() => import('./pages/pharmacy/pack/Pack'));
// const PharmaProductType = lazy(() => import('./pages/pharmacy/product-type/ProductType'));
// const PharmaSchedule = lazy(() => import('./pages/pharmacy/schedule/Schedule'));
// const PriceMaster = lazy(() => import('./pages/pharmacy/priceMaster/PriceMaster'));
const PharmaDrug = lazy(() => import('./pages/pharmacy/drug/Drug'));
const DiscountMaster = lazy(() => import('./pages/pharmacy/discountMaster/DiscountMaster'));
const MolecularGenericNameMaster = lazy(() => import('./pages/pharmacy/genericNameMaster/GenericNameMaster'));
const OverAllDiscountMaster = lazy(() => import('./pages/pharmacy/overAllDiscountMaster/OverAllDiscountMaster'));
const DiscountMapping = lazy(() => import('./pages/pharmacy/discountMapping/DiscountMapping'));
const TaxMapping = lazy(() => import('./pages/pharmacy/taxMapping/TaxMapping'));
const PriceMapping = lazy(() => import('./pages/pharmacy/priceMapping/PriceMapping'));
const PurchaseOrder = lazy(() => import('./pages/pharmacy/purchaseOrder/PurchaseOrder'));
const PurchaseStatus = lazy(() => import('./pages/pharmacy/purchaseStatus/PurchaseStatus'));
const WareHouseInventory = lazy(() => import('./pages/pharmacy/warehouseInventory/WareHouseInventory'));
const IndentOrder = lazy(() => import('./pages/pharmacy/indentOrder/IndentOrder'));
const IndentStatus = lazy(() => import('./pages/pharmacy/indentStatus/IndentStatus'));
const WarehouseIssuance = lazy(() => import('./pages/pharmacy/warehouseIssuance/WarehouseIssuance'));
const InventoryDrugStatus = lazy(() => import('./pages/pharmacy/inventoryDrug/InventoryDrug'));
const PatientOrders = lazy(() => import('./pages/pharmacy/patientOrders/PatientOrders'));
const PharmacySales = lazy(() => import('./pages/pharmacy/pharmacyOrder/PharmacySales'));
const SalesOrder = lazy(() => import('./pages/pharmacy/salesOrder/SalesOrder'));
const PendingOrders = lazy(() => import('./pages/pharmacy/pendingOrders/PendingOrders'));
const PaidOrder = lazy(() => import('./pages/pharmacy/paidOrder/PaidOrder'));
const CourierStatus = lazy(() => import('./pages/pharmacy/courierStatus/CourierStatus'));
const CourierPending = lazy(() => import('./pages/pharmacy/courierStatus/CourierPendingData'));
const AssignCourier = lazy(() => import('./pages/pharmacy/courierStatus/AssignedToCourier'));
const DispatchCourier = lazy(() => import('./pages/pharmacy/courierStatus/Dispatched'));
const DeliveredCourier = lazy(() => import('./pages/pharmacy/courierStatus/Delivered'));
const DispatchOrders = lazy(() => import('./pages/pharmacy/dispatchOrders/DispatchOrders'));
const Faqs = lazy(() => import('./pages/faqs/Faqs'));
const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy/PrivacyPolicy'));
const Termsandcondition = lazy(() => import('./pages/conditions/Conditions'));
const Needhelp = lazy(() => import('./pages/needHelp/Needhelp'));
const ChangePassword = lazy(() => import('./pages/changepassword/ChangePassword'));
// const InventoryHistory = lazy(() => import('./pages/pharmacy/inventoryHistory/InventoryHistory'));

const Error404 = lazy(() => import('./pages/error-pages/Error404'));
const Error500 = lazy(() => import('./pages/error-pages/Error500'));

const Login = lazy(() => import('./pages/user-pages/Login'));

const BlankPage = lazy(() => import('./pages/general-pages/BlankPage'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />

          <Route exact path="/profile" component={Profile} />

          <Route exact path="/setting/warehouse" component={WarehouseProfile} />
          <Route exact path="/setting/pharmacyDetails" component={PharmacyDetails} />
          <Route exact path="/setting/pharmacyShopping" component={PharmacyShopping} />
          <Route exact path="/product/categorymaster" component={Setting} />
          <Route exact path="/product/genericname-master" component={MolecularGenericNameMaster} />
          <Route exact path="/setting/pharmacyPreference" component={PharmacyPreference} />
          <Route exact path="/setting/VenderDetails" component={VenderDetails} />
          <Route exact path="/product/drug" component={PharmaDrug} />
          <Route exact path="/patient/drugcount" component={Drugcount} />
          <Route exact path="/product/taxmapping" component={TaxMapping} />
          <Route exact path="/discount/discountmaster" component={DiscountMaster} />
          <Route exact path="/discount/overalldiscount" component={OverAllDiscountMaster} />
          <Route exact path="/discount/discountmapping" component={DiscountMapping} />
          <Route exact path="/orders/purchaseOrder" component={PurchaseOrder} />
          <Route exact path="/orders/purchaseOrderStatus" component={PurchaseStatus} />
          <Route exact path="/orders/warehouseInventory" component={WareHouseInventory} />
          <Route exact path="/orders/indentOrder" component={IndentOrder} />
          <Route exact path="/orders/indentOrderStatus" component={IndentStatus} />
          <Route exact path="/orders/warehouseIssuance" component={WarehouseIssuance} />
          <Route exact path="/orders/stock" component={InventoryDrugStatus} />
          <Route exact path="/orders/pricemapping" component={PriceMapping} />
          <Route exact path="/sales/patientOrders" component={PatientOrders} />
          <Route exact path="/sales/pharmacySales" component={PharmacySales} />
          <Route exact path="/sales/salesOrder" component={SalesOrder} />
          <Route exact path="/sales/pendingOrders" component={PendingOrders} />
          <Route exact path="/sales/paidOrders" component={PaidOrder} />
          <Route exact path="/courier/courierstatus" component={CourierStatus} />
          <Route exact path="/courier/courierpending" component={CourierPending} />
          <Route exact path="/courier/courierassigned" component={AssignCourier} />
          <Route exact path="/courier/courierdispatch" component={DispatchCourier} />
          <Route exact path="/courier/courierdelivered" component={DeliveredCourier} />
          <Route exact path="/sales/dispatchOrders" component={DispatchOrders} />
          <Route exact path="/faqs" component={Faqs} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/termsandcondition" component={Termsandcondition} />
          <Route exact path="/needhelp" component={Needhelp} />
          <Route exact path="/changepassword" component={ChangePassword} />
          {/* <Route exact path="/pharmacy/drug-price-master" component={PriceMaster} /> */}
          {/* <Route exact path="/pharmacy/inventoryHistory" component={InventoryHistory} /> */}
          {/* <Route exact path="/pharmacy/drug-type" component={PharmaDrugType} />
          <Route exact path="/pharmacy/drug-pack" component={PharmaPack} />
          <Route exact path="/pharmacy/drug-product-type" component={PharmaProductType} />
          <Route exact path="/pharmacy/drug-schedule" component={PharmaSchedule} /> */}

          <Route path="/login" component={Login} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/general-pages/blank-page" component={BlankPage} />
          {getToken() ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
