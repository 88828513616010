export const masterReducer = (state = {}, action, name) => {
  switch (action.type) {
    case 'GET_CATEGORY_TYPE':
      return {
        ...state,
        categoryTypes: action.payload,
      };
    case 'GET_CATEGORY':
      return {
        ...state,
        [action.name]: action.payload,
      };
    case 'GET_CATEGORY_ACTION':
      return {
        ...state,
        action: action.payload,
      };
    case 'GET_CATEGORYGEN':
      return {
        ...state,
        categorygen: action.payload,
      };
    case 'GET_PATIENTDRUG_TYPE':
      return {
        ...state,
        patientdrug: action.payload,
      };
    case 'GET_CATEGORY1':
      return {
        ...state,
        category: action.payload,
      };
    default:
      return state;
  }
};
export default masterReducer;
