import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { getToken, handleWarnAlert, removeToken } from '../utils/utils';
import { saveAs } from 'file-saver';
import { loadingAction } from '../redux/actions/actions';
export const baseURL = process.env.REACT_APP_BASE_URL + '/diahome/v1/api/pharmacy-service';
const headers = () => {
  return {
    applicationLabel: 'Pharmacy-Portal',
    correlationId: uuidv4(),
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };
};
const triggerToastSuccess = (message) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
const triggerToastError = (message) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};

export const errormsg = (error, props) => {
  if (error && error.response?.status === 404) {
    triggerToastError(`Not found`);
  } else if (error && error.response?.status === 401) {
    const err = error.response.data.errors;
    err.map((value) => triggerToastError(`${value.message}`));
    if (props) logoutService(props);
  } else if (error && error.response?.status === 402) {
    const err = error.response.data.errors;
    err.map((value) => triggerToastError(`${value.message}`));
  } else if (error && error.response?.status >= 500) {
    triggerToastError(`Server Error`);
  } else if (error && error.response && error.response.data.errors?.length > 0) {
    const err = error.response.data.errors;
    err.map((value) => triggerToastError(`${value.message}`));
  } else {
    triggerToastError(`${error && error.response && error.response.message}`);
  }
};
export const login = async (d) => {
  const data = JSON.stringify(d);
  const config = {
    method: 'post',
    url: `${baseURL}/employee/login/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        localStorage.setItem('accessToken', response.data.data.accessToken);
        localStorage.setItem('basicProfile', JSON.stringify(response.data.data.basicProfile));
        localStorage.setItem('email', response.data.data.email);
        localStorage.setItem('roles', response.data.data.roles);
        localStorage.setItem('username', response.data.data.username);
        localStorage.setItem('functions', response.data.data.functions);
        localStorage.setItem('empId', response.data.data.basicProfile.empId);
        localStorage.setItem('loginDuration', response.data.data.loginDuration);
        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const logout = async (data) => {
  const config = {
    method: 'post',
    url: `${baseURL}/employee/logout/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        triggerToastSuccess(response.data.data.message);
        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const logoutService = (props) => {
  props.history.push({
    pathname: '/login',
    state: {},
  });
  return removeToken();
};
export const forgetpasshr = (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/employee/forgot-password/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const verifyOtphr = (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/verify-otp/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getCategoryList = (categoryType, props) => {
  const data = JSON.stringify({
    searchCriteria: {
      categoryType: categoryType,
      isActive: true,
    },
  });

  const config = {
    method: 'post',
    url: `${baseURL}/masters/categories/findall`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTemplateStatusTypeService = () => {
  const config = {
    method: 'GET',
    url: `${baseURL}/bulk-upload/status/lists/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getUploadTemplateStatusService = (searchCriteria) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/bulk-upload/all/list/`,
    headers: headers(),
    data: searchCriteria,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getMolecularNamesList = (props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/drugs/molecular-names/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientdrug = (props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/patient-pharmacy-order/get/ordersToNotify/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const updateActiveInactiveStatus = async (_d, path, method, props) => {
  const config = {
    method: method,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: _d,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      errormsg(error, props);
      return error.response;
    });
};
export const createAdminCategory = (method, data, props) => {
  const config = {
    method: method,
    url: `${baseURL}/masters/categories`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      errormsg(error, props);
      return error.response;
    });
};
export const getIfcecode = async (id, props) => {
  const config = {
    method: 'GET',
    url: `https://ifsc.razorpay.com/${id}`,
  };
  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const changepaid = (method, data, path) => {
  const config = {
    method: method,
    url: `${baseURL}/orders/patient-order/${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const updateOrderAdminCategory = (method, data) => {
  const config = {
    method: method,
    url: `${baseURL}/masters/categories/list-update/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const getpharmacyState = (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/pharmacy/get/locations/states`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      return error.response;
    });
};
export const getpharmacylist = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/pharmacy/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return error.response;
    });
};
export const getupdatepharmacy = (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/pharmacy/update/priority/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getcityData = (data, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/pharmacy/get/locations/cities?state=${data}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      return error.response;
    });
};
export const getpincodeData = (data, data1, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/pharmacy/get/locations/pincodes?state=${data}&city=${data1}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      return error.response;
    });
};
export const getAdminCategoryType = (props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/masters/categories/types/findall`,
    headers: headers(),
    data: {
      itemsPerPage: 0,
      pageNumber: 0,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAdminCategory = (categoryType, isActive, itemPerPage, pageNumber, name, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/masters/categories/findall`,
    headers: headers(),
    data: {
      itemsPerPage: itemPerPage || 0,
      pageNumber: pageNumber || 0,
      searchCriteria: {
        categoryType: categoryType,
        isActive: isActive,
        name: name || undefined,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      return error.response;
    });
};
export const fileAttachmentUploadURLGen = async (fileDetails, props, picUploadBody) => {
  try {
    return await axios({
      method: 'POST',
      url: `${baseURL}/file-storage/get-signed-url/`,
      headers: {
        applicationLabel: 'File-Service',
        correlationId: uuidv4(),
        Authorization: `Bearer ${getToken()}`,
      },
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.fileName;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);
            if (response.status === 200) return fileUrl;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        triggerToastError(err.response);
      });
  } catch (error) {
    return error.response;
  }
};

export const fileAttachmentUpload = async (file, props, url, fileName, fileUrl) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      axios
        .request({
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': file?.type,
          },
          method: 'PUT',
          data: reader.result,
          url: url,
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100 - 10;
            console.log(uploadProgress, 'uploadProgress');
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    };
    reader.onerror = (err) => {
      err = { error: { message: 'file read error' } };
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });
};
export const fileAttachmentUploadTemplate = (file, url) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      axios
        .request({
          headers: {
            'Content-Type': `${file[0]?.type}`,
            'Content-Disposition': `attachment; filename=${file[0].name}`,
            applicationLabel: 'Pharmacy-Portal',
            correlationId: uuidv4(),
            Authorization: `Bearer ${getToken()}`,
          },
          method: 'POST',
          data: Buffer.from(reader.result),
          url: url,
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100 - 10;
            console.log(uploadProgress, 'uploadProgress');
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          errormsg(err);
          dispatch(loadingAction(false));
          reject(err.response);
        });
    };
    reader.onerror = (err) => {
      err = { error: { message: 'file read error' } };
      reject(err);
    };
    reader.readAsArrayBuffer(file[0]);
  });
};

export const getselectcity = async (city) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/masters/places/search/map?query=${city}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getpharmacyEmpDetailService = (methord, data, props) => async (dispatch) => {
  const config = {
    method: methord,
    url: `${baseURL}/users/listEmployee/`,
    headers: headers(),
    data: methord === 'DELETE' || methord === 'GET' ? undefined : data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const pharmacyDetailService = (methord, data, id, props, url) => async (dispatch) => {
  if (id) {
    id = id + '/';
  }
  const config = {
    method: methord,
    url: `${baseURL}/pharmacy/${url || id}`,
    headers: headers(),
    data: methord === 'DELETE' || methord === 'GET' ? undefined : data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const pharmacyDetailServicebydata = (methord, data, id, props) => {
  const config = {
    method: methord,
    url: `${baseURL}/pharmacy/${id}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const pharmacyAllDetailService = (props) => async (dispatch) => {
  const data = {
    searchCriteria: {
      isPrimary: true,
    },
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/pharmacy/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getpharmacy = (props, day) => {
  const data = {
    searchCriteria: day,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/pharmacy/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getpharmacyInfo = (props, id) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/pharmacy/detail/all/${id}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getpharmacycleardate = (props, id) => {
  // const data = {
  //   searchCriteria: day,
  // };
  const config = {
    method: 'get',
    url: `${baseURL}/pharmacy/${id}`,
    headers: headers(),
    // data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const vendorDetailService = (methord, data, id, props, url) => async (dispatch) => {
  if (id) {
    id = id + '/';
  }
  const config = {
    method: methord,
    url: `${baseURL}/vendor/${url || id}`,
    headers: headers(),
    data: methord === 'DELETE' || methord === 'GET' ? undefined : data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const warehouseDetailService = (methord, data, id, props, url) => async (dispatch) => {
  if (id) {
    id = id + '/';
  }
  const config = {
    method: methord,
    url: `${baseURL}/warehouse/${url || id}`,
    headers: headers(),
    data: methord === 'DELETE' || methord === 'GET' ? undefined : data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const drugService = (methord, data, id, props, url) => async (dispatch) => {
  if (id) {
    id = id + '/';
  }
  const config = {
    method: methord,
    url: `${baseURL}/drugs/${url || id}`,
    headers: headers(),
    data: methord === 'DELETE' || methord === 'GET' ? undefined : data,
  };

  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch((error) => {
      errormsg(error, props);
    });
};
export const getPatientProfileData = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/profiles/all/list/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getCourier = (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/orders/courier/list/agent-providers/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getCourierInfo = (courierId, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/orders/courier/get/courier-details/${courierId}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const assignCourier = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/orders/courier/create/booking/courier-agent/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getCourierPaymentinfo = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/orders/courier/quote/api/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const courierStatus = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/orders/courier/track/courier-status/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const courierCancel = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/orders/courier/cancel/booking/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getCourierData = (status, pageNumber, itemPerPage, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/orders/patient-pharmacy-order/get-by/status/${status}?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPendingCourier = (status, pageNumber, itemPerPage, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/orders/patient-pharmacy-order/get/pending-courier/orders/?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const postOrdertoCourier = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createMolecularData = (path, method, data, id, props) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: method,
    url: `${baseURL}/${pathUrl}${id ? id + '/' : ''}`,
    headers: headers(),
    data: method === 'DELETE' ? undefined : data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.data.message;
      triggerToastSuccess(msg);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getDiscountMasterList = (method, data, id, props) => {
  const config = {
    method: method,
    url: `${baseURL}/drugs/discounts/${id ? id + '/' : ''}`,
    headers: headers(),
    data: method === 'POST' || method === 'PUT' ? data : undefined,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getOverAllDiscountMasterList = (method, data, id, props) => {
  const config = {
    method: method,
    url: `${baseURL}/drugs/overall-discounts/${id ? id + '/' : ''}`,
    headers: headers(),
    data: method === 'POST' || method === 'PUT' ? data : undefined,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTaxMappingMasterList = (method, data, id, props) => {
  const config = {
    method: method,
    url: `${baseURL}/drugs/tax-mappings/${id ? id + '/' : ''}`,
    headers: headers(),
    data: method === 'POST' || method === 'PUT' ? data : undefined,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTaxMappingMasterPaginationList = (searchCriteria, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/drugs/tax-mappings/all/list/`,
    headers: headers(),
    data: searchCriteria,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPurchaseOrderList = (path, method, data, id) => {
  const config = {
    method: method,
    url: `${baseURL}/${path}${id ? id + '/' : ''}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getIndentOrderList = (path, method, data, id) => {
  const config = {
    method: method,
    url: `${baseURL}/${path}${id ? id + '/' : ''}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getInventoryDrugList = (path, method, data, id) => {
  const config = {
    method: method,
    url: `${baseURL}/${path}${id ? id + '/' : ''}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getPurchaseRequisationBalanceList = (id) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/${id}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getPurchaseRequisationList = () => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/purchase-requisition/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const movePoToWareHouseService = (wareHouse, po) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/drugs/warehouse-inventory/add/drugs/${po}/${wareHouse}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getDrugCountList = (id) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/purchase-requisition/get-drug-count/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getPatientPharmacyOrderList = (status, pageNumber, itemPerPage, orderId, patientId, isActive, props) => {
  const data = {
    searchCriteria: {
      status: status,
      isActive: isActive,
    },
    itemsPerPage: itemPerPage,
    pageNumber: pageNumber,
  };
  if (orderId) data.searchCriteria.orderId = orderId;
  if (patientId?.patientId) data.searchCriteria.patientId = patientId?.patientId;
  if (patientId?.createdOn) data.searchCriteria.createdOn = patientId?.createdOn;
  if (patientId?.orderId) data.searchCriteria.orderId = patientId?.orderId;
  const config = {
    method: 'POST',
    url: `${baseURL}/orders/patient-order/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const deletePatientPharmacyOrderList = async (id, props) => {
  const config = {
    method: 'DELETE',
    url: `${baseURL}/orders/patient-order/${id}`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientPharmacyNotPaidOrderList = (pageNumber, itemPerPage, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/patient-pharmacy-order/get-by/payment-status/Not Paid/?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
// export const getPatientPharmacyPaidOrderList = (status, pageNumber, itemPerPage, props) => {
//   const config = {
//     method: 'GET',
//     url: `${baseURL}/orders/patient-pharmacy-order/get-by/payment-status/${status}/?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
//     headers: headers(),
//   };

//   return axios(config)
//     .then(function (response) {
//       return response.data.data;
//     })
//     .catch(function (error) {
//       errormsg(error, props);
//     });
// };
export const getPatientPharmacyPaidOrderList = (status, pageNumber, itemPerPage, props) => {
  const payload = {
    orderBy: 'orderDate',
    isAscending: false,
    pageNumber: pageNumber,
    totalSize: itemPerPage,
    searchCriteriaList: status,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/orders/patient-pharmacy-order/search-order/`,
    headers: headers(),
    data: payload,
  };

  return axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientPharmacyByStatus = (status, id, type, pageNumber, itemPerPage, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/patient-pharmacy-order/${
      type === 'byId' ? id : `get-by/status/${status}`
    }?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      if (response.status === 204) return handleWarnAlert('No result for the given data');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getOrderHistoryService = (orderId, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/patient-pharmacy-order/get/history/${orderId}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getWarehouseInventoryList = () => {
  const config = {
    method: 'POST',
    url: `${baseURL}/drugs/warehouse-inventory/all/list/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getPriceMappingListService = () => {
  const data = {
    searchCriteria: {},
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/drugs/price-mappings/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getWareHouseInventoryDataService = (id, drugName) => {
  const data = {
    searchCriteria: {
      warehouseId: id,
      isActive: true,
      status: 'STOCK_AVAILABLE',
    },
    sortInstruction: {
      attributeName: 'createdOn',
      sortDirection: -1,
    },
  };
  if (drugName) data.searchCriteria.drugName__icontains = drugName;
  const config = {
    method: 'POST',
    url: `${baseURL}/drugs/warehouse-inventory/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const downloadDocument = async (file, props) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${baseURL}/file-storage/get-signed-url/`,

      headers: headers(),
      data: file,
    })
      .then((result) => {
        return resolve(result.data?.data?.signedUrl);
      })
      .catch((err) => {
        errormsg(err, props);
        return err.response;
      });
  });
};
export const fileAttachmentUploadURLGen1 = async (fileDetails, props, picUploadBody) => {
  try {
    return await axios({
      method: 'POST',
      url: `${baseURL}/file-storage/get-signed-url/`,
      headers: headers(),
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.filename;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);
            if (response.status === 200) return fileName;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {
    return error.response;
  }
};
export const getDrugBalanceService = async (pharmacyId, drugId, props) => {
  const data = {
    pharmacyId: pharmacyId,
    drugId: drugId,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/drugs/pharmacy-inventory/get-drug-count/available/`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updatePatientOrderService = async (id, data, props) => {
  const config = {
    method: 'PUT',
    url: `${baseURL}/orders/patient-order/${id}/`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getOrderIdDataService = async (id) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/drugs/pharmacy-inventory/drugs/patient-order/${id}/`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getPatientOrderByIdService = async (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/patient-order/${id}/`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getDraftDataByPatientIDandOrderID = async (patientId, orderId, props) => {
  const data = {
    searchCriteria: {
      orderId: orderId,
      patientId: patientId,
    },
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/orders/patient-order/draft/all/list/`,
    headers: headers(),
    data,
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const saveOrderService = async (data, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/orders/patient-order/draft/`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getOrderCourierAmmount = async (data, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/orders/patient-order/draft/`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createOrderService = async (data, id, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/orders/patient-pharmacy-order/create/${id}/?inv=true`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientDataListService = async (props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/patients/profiles/allprofile/`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientAddressDataListService = async (patientId, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/patients/profiles/${patientId}/addresses`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientInvoicePDFService = async (Id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/patient-pharmacy-order/generate/invoice/${Id}/`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data.signedUrl;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getdrugMaster = async (search) => {
  const data = {
    searchCriteria: {
      drugName__icontains: search,
    },
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/drugs/all/list/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const fileAttachmentDownloadURLGenTemplate = async (fileName) => {
  let signedUrl;
  await axios({
    method: 'POST',
    url: `${baseURL}/file-storage/get-signed-url/`,
    headers: headers(),
    data: {
      filename: fileName,
      objectType: 'close',
      apiType: 'download',
    },
  })
    .then((res) => {
      if (res.data.status >= 200 && res.data.status < 400) {
        signedUrl = res.data.data.signedUrl;
      }
    })
    .catch((error) => {
      errormsg(error);
    });
  return signedUrl;
};
export const fileAttachmentDownloadURLGen = async (fileName, props) => {
  let signedUrl;
  try {
    await axios({
      method: 'POST',
      url: `${baseURL}/file-storage/get-signed-url/`,
      headers: headers(),
      data: {
        filename: fileName,
        objectType: 'close',
        apiType: 'download',
      },
    })
      .then((res) => {
        if (res.data.status >= 200 && res.data.status < 400) {
          signedUrl = res.data.data.signedUrl;
        }
      })
      .catch((error) => {
        errormsg(error, props);
      });
  } catch (error) {}
  return signedUrl;
};

export const fileAttachmentDownload = (url, fileType, fileName, props) => async (dispatch, getState) => {
  try {
    await axios({
      method: 'GET',
      url: `${url}`,
      responseType: 'arraybuffer',
    })
      .then((res) => {
        if (res.status >= 200) {
          if (res.data) {
            const blob = new Blob([res.data], { type: fileType });
            saveAs(blob, fileName);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {
    return error.response;
  }
};
export const fileAttachmentDownloadTemplate = (url, fileType, fileName) => async (dispatch, getState) => {
  try {
    await axios({
      method: 'GET',
      url: `${url}`,
      responseType: 'blob',
      headers: headers(),
    })
      .then((res) => {
        if (res.status >= 200) {
          if (res.data) {
            const blob = new Blob([res.data], { type: fileType });
            saveAs(blob, fileName);
          }
        }
      })
      .catch((err) => {
        errormsg(err);
      });
  } catch (error) {
    return error.response;
  }
};

export const Changepass = (data, changePasswordSuccess) => {
  const config = {
    method: 'put',
    url: `${baseURL}/employee/change-password/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      if (changePasswordSuccess) changePasswordSuccess();
      triggerToastSuccess(response.data.data);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};

export const getEligibleDiscount = async (amt, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/drugs/overall-discounts/find/match/?amount=${amt}`,
    headers: headers(),
  };
  let result;
  await axios(config)
    .then(function (response) {
      result = response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      result = {};
    });
  return result;
};
export const getOrderStatusListService = async (props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/patient-pharmacy-order/get-list/order-status/`,
    headers: headers(),
  };
  let result;
  await axios(config)
    .then(function (response) {
      result = response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      result = [];
    });
  return result;
};
export const getOrderStatusChangeListService = async (props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/orders/patient-pharmacy-order/get-list/order-delivery-status/`,
    headers: headers(),
  };
  let result;
  await axios(config)
    .then(function (response) {
      result = response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      result = [];
    });
  return result;
};
export const recordOfLoginandLogout = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/users/record-attendance/create/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const sendPaymentLinkService = async (data, props) => {
  const config = {
    method: 'PUT',
    url: `${baseURL}/orders/patient-pharmacy-order/update/order/?link=true`,
    headers: headers(),
    data,
  };

  return await axios(config)
    .then(function (response) {
      triggerToastSuccess('Payment Link Sent Successfully');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const changeStatusService = async (data, props) => {
  const config = {
    method: 'PATCH',
    url: `${baseURL}/orders/patient-pharmacy-order/update/${data.id}/${data.status}/`,
    headers: headers(),
    data,
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      // triggerToastSuccess(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
