import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getfunctions } from '../utils/utils';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        if (i !== 'func') this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    const functions = getfunctions();
    if (functions) {
      const f = functions.split(',');
      this.setState({ func: f });
    } else {
      this.setState({ func: [] });
    }
    this.onRouteChanged();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          document.body.classList.toggle('sidebar-icon-only');
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          document.body.classList.toggle('sidebar-icon-only');
          el.classList.remove('hover-open');
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      if (i !== 'func') this.setState({ [i]: false });
    });
    const dropdownPaths = [
      { path: '/settings', state: 'settingsMenuOpen' },
      { path: '/company', state: 'companyMenuOpen' },
      { path: '/employee', state: 'employeeMenuOpen' },
      { path: '/roaster', state: 'roasterMenuOpen' },
      { path: '/leave', state: 'mangeMenuOpen' },
      { path: '/role', state: 'roleMenuOpen' },
      { path: '/referral', state: 'referralMenuOpen' },
      { path: '/pharmacy', state: 'pharmacyMenuOpen' },
      { path: '/product', state: 'productOpen' },
      { path: '/discount', state: 'discountOpen' },
      { path: '/sales', state: 'salesOpen' },
      { path: '/orders', state: 'ordersOpen' },
      { path: '/inventory', state: 'inventoryOpen' },
      { path: '/setting', state: 'settingOpen' },
      { path: '/lab', state: 'labMenuOpen' },
      { path: '/skill', state: 'skillMenuOpen' },
      { path: '/coupons', state: 'couponMenuOpen' },
      { path: '/patient', state: 'patientprofileMenu' },
      { path: '/courier', state: 'courierMenu' },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  getLinkAccess(funcName) {
    const { func } = this.state;
    return func && func.includes(funcName);
  }

  getLinkHeadAccess(links) {
    const { func } = this.state;
    return func && func.some((item) => links.includes(item));
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Home</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          {this.getLinkHeadAccess([
            'MANAGE_PHARMACY',
            'MANAGE_DRUG_PACK',
            'MANAGE_DRUG_PRODUCT_TYPE',
            'MANAGE_DRUG_SCHEDULE',
            'MANAGE_DRUG_TYPE',
            'MANAGE_DRUG',
          ]) && (
            <li className={this.isPathActive('/setting') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.settingOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('settingOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Setting</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-settings menu-icon"></i>
              </div>
              <Collapse in={this.state.settingOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/setting/pharmacyDetails') ? 'nav-link active' : 'nav-link'}
                        to="/setting/pharmacyDetails"
                      >
                        Pharmacy Master
                      </Link>
                    </li>
                  )}
                  {/* {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/setting/pharmacyShopping') ? 'nav-link active' : 'nav-link'}
                        to="/setting/pharmacyShopping"
                      >
                        Pharmacy Shipping
                      </Link>
                    </li>
                  )} */}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/setting/warehouse') ? 'nav-link active' : 'nav-link'}
                        to="/setting/warehouse"
                      >
                        Warehouse Master
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/setting/VenderDetails') ? 'nav-link active' : 'nav-link'}
                        to="/setting/VenderDetails"
                      >
                        Vendor Master
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/setting/pharmacyPreference') ? 'nav-link active' : 'nav-link'}
                        to="/setting/pharmacyPreference"
                      >
                        Pharmacy Preference
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess([
            'MANAGE_PHARMACY',
            'MANAGE_DRUG_PACK',
            'MANAGE_DRUG_PRODUCT_TYPE',
            'MANAGE_DRUG_SCHEDULE',
            'MANAGE_DRUG_TYPE',
            'MANAGE_DRUG',
          ]) && (
            <li className={this.isPathActive('/product') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.productOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('productOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Product Master</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-cube menu-icon"></i>
              </div>
              <Collapse in={this.state.productOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/product/drug') ? 'nav-link active' : 'nav-link'}
                        to="/product/drug"
                      >
                        Drug
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/product/categorymaster') ? 'nav-link active' : 'nav-link'}
                        to="/product/categorymaster"
                      >
                        Drug Master
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/product/genericname-master') ? 'nav-link active' : 'nav-link'}
                        to="/product/genericname-master"
                      >
                        Generic Name Master
                      </Link>
                    </li>
                  )}

                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/product/taxmapping') ? 'nav-link active' : 'nav-link'}
                        to="/product/taxmapping"
                      >
                        Tax Mapping
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {/* {this.getLinkHeadAccess([
            'MANAGE_PHARMACY',
            'MANAGE_DRUG_PACK',
            'MANAGE_DRUG_PRODUCT_TYPE',
            'MANAGE_DRUG_SCHEDULE',
            'MANAGE_DRUG_TYPE',
            'MANAGE_DRUG',
          ]) && (
            <li className={this.isPathActive('/orders') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.ordersOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('ordersOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Purchase Master</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-basket menu-icon"></i>
              </div>
              <Collapse in={this.state.ordersOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/orders/purchaseOrder') ? 'nav-link active' : 'nav-link'}
                        to="/orders/purchaseOrder"
                      >
                        Purchase Order
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/orders/purchaseOrderStatus') ? 'nav-link active' : 'nav-link'}
                        to="/orders/purchaseOrderStatus"
                      >
                        Purchase Order Status
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/orders/warehouseIssuance') ? 'nav-link active' : 'nav-link'}
                        to="/orders/warehouseIssuance"
                      >
                        Warehouse Issuance
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/orders/warehouseInventory') ? 'nav-link active' : 'nav-link'}
                        to="/orders/warehouseInventory"
                      >
                        Warehouse Inventory
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/orders/indentOrder') ? 'nav-link active' : 'nav-link'}
                        to="/orders/indentOrder"
                      >
                        Indent Order
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/orders/indentOrderStatus') ? 'nav-link active' : 'nav-link'}
                        to="/orders/indentOrderStatus"
                      >
                        Indent Order Status
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/orders/stock') ? 'nav-link active' : 'nav-link'}
                        to="/orders/stock"
                      >
                        Stock
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/orders/pricemapping') ? 'nav-link active' : 'nav-link'}
                        to="/orders/pricemapping"
                      >
                        Price Master
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {this.getLinkHeadAccess([
            'MANAGE_PHARMACY',
            'MANAGE_DRUG_PACK',
            'MANAGE_DRUG_PRODUCT_TYPE',
            'MANAGE_DRUG_SCHEDULE',
            'MANAGE_DRUG_TYPE',
            'MANAGE_DRUG',
          ]) && (
            <li className={this.isPathActive('/discount') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.discountOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('discountOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Discount Master</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-tag-plus menu-icon"></i>
              </div>
              <Collapse in={this.state.discountOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/discount/discountmaster') ? 'nav-link active' : 'nav-link'}
                        to="/discount/discountmaster"
                      >
                        Discount Group
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/discount/discountmapping') ? 'nav-link active' : 'nav-link'}
                        to="/discount/discountmapping"
                      >
                        Group Mapping
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/discount/overalldiscount') ? 'nav-link active' : 'nav-link'}
                        to="/discount/overalldiscount"
                      >
                        Overall Discount
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess([
            'MANAGE_PHARMACY',
            'MANAGE_DRUG_PACK',
            'MANAGE_DRUG_PRODUCT_TYPE',
            'MANAGE_DRUG_SCHEDULE',
            'MANAGE_DRUG_TYPE',
            'MANAGE_DRUG',
          ]) && (
            <li className={this.isPathActive('/sales') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.salesOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('salesOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Sales</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-sale menu-icon"></i>
              </div>
              <Collapse in={this.state.salesOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/sales/patientOrders') ? 'nav-link active' : 'nav-link'}
                        to="/sales/patientOrders"
                      >
                        Patient Orders
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/sales/pharmacySales') ? 'nav-link active' : 'nav-link'}
                        to="/sales/pharmacySales"
                      >
                        Pharmacy Sales
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/sales/salesOrder') ? 'nav-link active' : 'nav-link'}
                        to="/sales/salesOrder"
                      >
                        Sales Order
                      </Link>
                    </li>
                  )}
                  {/* {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/sales/pendingOrders') ? 'nav-link active' : 'nav-link'}
                        to="/sales/pendingOrders"
                      >
                        Pending Orders
                      </Link>
                    </li>
                  )} */}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/sales/paidOrders') ? 'nav-link active' : 'nav-link'}
                        to="/sales/paidOrders"
                      >
                        Pharmacy Orders
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess([
            'MANAGE_PHARMACY',
            'MANAGE_DRUG_PACK',
            'MANAGE_DRUG_PRODUCT_TYPE',
            'MANAGE_DRUG_SCHEDULE',
            'MANAGE_DRUG_TYPE',
            'MANAGE_DRUG',
          ]) && (
            <li className={this.isPathActive('/courier') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.courierMenu ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('courierMenu')}
                data-toggle="collapse"
              >
                <span className="menu-title">Courier</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-van-utility menu-icon"></i>
              </div>
              <Collapse in={this.state.courierMenu}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/courier/courierstatus') ? 'nav-link active' : 'nav-link'}
                        to="/courier/courierstatus"
                      >
                        Courier Status
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/courier/courierpending') ? 'nav-link active' : 'nav-link'}
                        to="/courier/courierpending"
                      >
                        Courier Pending
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/courier/courierassigned') ? 'nav-link active' : 'nav-link'}
                        to="/courier/courierassigned"
                      >
                        Assigned Couriers
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/courier/courierdispatch') ? 'nav-link active' : 'nav-link'}
                        to="/courier/courierdispatch"
                      >
                        Dispatched Couriers
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/courier/courierdelivered') ? 'nav-link active' : 'nav-link'}
                        to="/courier/courierdelivered"
                      >
                        Delivered Couriers
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {/* {this.getLinkHeadAccess([
            'MANAGE_PHARMACY',
            'MANAGE_DRUG_PACK',
            'MANAGE_DRUG_PRODUCT_TYPE',
            'MANAGE_DRUG_SCHEDULE',
            'MANAGE_DRUG_TYPE',
            'MANAGE_DRUG',
          ]) && (
            <li className={this.isPathActive('/inventory') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.inventoryOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('inventoryOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Inventory</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-package-variant menu-icon"></i>
              </div>
              <Collapse in={this.state.inventoryOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/inventory/inventoryDrug') ? 'nav-link active' : 'nav-link'}
                        to="/inventory/inventoryDrug"
                      >
                        Stock
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {this.getLinkHeadAccess(['MANAGE_PATIENT_PROFILE']) && (
            <li className={this.isPathActive('/patient') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.patientprofileMenu ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('patientprofileMenu')}
                data-toggle="collapse"
              >
                <span className="menu-title">Patients</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account-card-details menu-icon"></i>
              </div>
              <Collapse in={this.state.patientprofileMenu}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_PATIENT_PROFILE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/patient/profile') ? 'nav-link active' : 'nav-link'}
                        to="/patient/profile"
                      >
                        Profile
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          <li className={this.isPathActive('/patient/drugcount') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/patient/drugcount">
              <span className="menu-title">Patient Drug Status</span>
              <i className="mdi mdi-counter menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/faqs') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/faqs">
              <span className="menu-title">FAQ</span>
              <i className="mdi mdi-comment-question-outline menu-icon"></i>
            </Link>
          </li>

          <li className={this.isPathActive('/privacypolicy') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/privacypolicy">
              <span className="menu-title">Privacy Policy</span>
              <i className="mdi mdi-file-lock menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/termsandcondition') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/termsandcondition">
              <span className="menu-title">Terms and Conditions</span>
              <i className="mdi mdi-file-document menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/needhelp') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/needhelp">
              <span className="menu-title">Need Help ?</span>
              <i className="mdi mdi-help-box menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/changepassword') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/changepassword">
              <span className="menu-title">Change Password</span>
              <i className="mdi mdi-textbox-password menu-icon"></i>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  location: PropTypes.any,
  func: PropTypes.array,
};
export default withRouter(Sidebar);
